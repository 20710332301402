import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';

// project import
import { Box } from '@mui/system';
import biglogo from 'images/drawer-big-logo.png';
import logo from 'images/drawer-logo.png';
import DrawerHeaderStyled from './DrawerHeaderStyled';

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {
  const theme = useTheme();

  return (
    <DrawerHeaderStyled theme={theme} open={open}>
      <Box sx={{ width: 5, height: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {open ? (
          <Box sx={{ width: 5, height: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', pl: 25, pt: 4 }}>
            {' '}
            <img src={biglogo} alt="Logo" />{' '}
          </Box>
        ) : (
          <img src={logo} alt="Logo" />
        )}
      </Box>
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool
};

export default DrawerHeader;
